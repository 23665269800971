<template>
  <div>
    <v-btn v-if="showEdit" small color="secondary" @click="$emit('edit')" icon>
      <v-icon size="18"> mdi-pencil </v-icon>
    </v-btn>

    <v-btn
      v-if="showDelete"
      small
      color="#9E9E9E"
      @click="$emit('delete')"
      class="ml-2"
      icon
    >
      <v-icon size="18"> mdi-trash-can-outline </v-icon>
    </v-btn>
    <v-btn v-if="showNext" small color="#169bd5">
      <span style="color: white"> {{ showNextText }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ActionButtons",
  props: {
    showDelete: {
      default: true
    },
    showEdit: {
      default: true
    },
    showNext: {
      default: false
    },
    showNextText: {
      default: "Button"
    }
  }
};
</script>

<template>
  <div class="content-wrapper elevation-3 mx-4 my-4">
    <v-skeleton-loader
      v-bind="$helper.skeletonConfig"
      type="button, table-thead, table-tbody"
      :loading="isPageLoading"
      class="pa-5"
    >
      <slot name="header" />
      <div v-if="hasTopOfTableSlot" class="d-flex my-4 top-of-table">
        <slot name="top-of-table" />
      </div>
      <v-row>
        <v-col class="data-table-col">
          <slot name="data-table" />
        </v-col>
      </v-row>
      <slot name="form" />
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "Content",
  props: {
    pageTitle: {
      default: ""
    },
    isPageLoading: {
      default: false
    }
  },
  computed: {
    hasTopOfTableSlot() {
      return !!this.$slots["top-of-table"];
    }
  }
};
</script>
